.leaflet-container {
  /* width: 100vw;
    height: 100vh; */
  width: 100%;
  height: 500px;
}

* {
  text-decoration: none;
}

.leaflet-venue-icon {
  /* background: 'rgba(0,0,0,.5)'; */
  background: 'red';
}

/* loader */
.loader {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: inline-block;
  border-left: 5px solid rgb(255, 61, 61);
  border-top: 5px solid rgb(255, 61, 61);
  border-right: 5px solid transparent;
  box-sizing: border-box;
  animation: rotation .7s linear infinite;
}

.loader-lg {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: inline-block;
  border-left: 5px solid rgb(255, 61, 61);
  border-top: 5px solid rgb(255, 61, 61);
  border-right: 5px solid transparent;
  box-sizing: border-box;
  animation: rotation .7s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.badge-in-danger {
  background: rgb(241, 28, 56);
  display: inline-block;
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 5px;
  color: white;
}

.badge-in-success {
  background: rgb(69, 168, 127);
  display: inline-block;
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 5px;
  color: white;
}

.badge-in-secondary {
  background: rgba(95, 95, 95, 0.808);
  display: inline-block;
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 5px;
  color: white;
}

.badge-in-primary {
  background: rgba(16, 117, 201, 0.808);
  display: inline-block;
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 5px;
  color: white;
}

/* https://cssloaders.github.io/ */
/* https://loading.io/css/ */
/* loading css */