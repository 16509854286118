body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.subtitle {
  background: rgba(0, 0, 0, .5);
}

.active-animation {
  animation-name: parpadeo;
  padding: 0px 4px;
  border-radius: 50%;
  background: rgb(15, 206, 15);
  display: inline-flex;
  vertical-align: middle;
  margin-bottom: 4px;
  margin-left: 5px;
  font-size: 6px;
  color: transparent;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 2.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1.0;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1.0;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1.0;
  }
}

.content-basic {
  display: flex;
  overflow-x: auto;
}

.table-basic {
  border-collapse: collapse;
  width: 100%;
  border: 2px solid #495057;
  min-width: 600px;
}

.td-basic {
  /* border: 1px solid #495057; */
  padding: 5px;
}

.fw-600 {
  font-weight: 600;
}